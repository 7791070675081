import { FC, useRef } from 'react';

import { HSpacing, Text, TooltipHover } from '@hh.ru/magritte-ui';
import { QuestionCircleOutlinedSize16, UserDoubleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

interface VacancyAdditionalAcceptProps {
    icon: string;
    text: string;
    hint: string;
    isEmployerView?: boolean;
}

const VacancyAdditionalAccept: FC<VacancyAdditionalAcceptProps> = ({ icon, text, hint, isEmployerView = false }) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    return (
        <div className="vacancy-icon-wrapper">
            <span className={`vacancy-icon vacancy-icon_${icon}`}>
                {icon === 'kids' && <UserDoubleOutlinedSize16 />}
                {icon === 'handicapped' && <span className="accept-handicapped-icon" />}
            </span>
            <Text Element="span" typography="label-2-regular">
                {text}
                <HSpacing default={isEmployerView ? 8 : 4} />
                <span className="vacancy-icon-hint">
                    <span ref={activatorRef}>
                        <QuestionCircleOutlinedSize16 initialColor={isEmployerView ? 'secondary' : 'primary'} />
                    </span>
                    <TooltipHover placement="top-center" activatorRef={activatorRef}>
                        {hint}
                    </TooltipHover>
                </span>
            </Text>
        </div>
    );
};

export default VacancyAdditionalAccept;
