import classnames from 'classnames';

import { ButtonStyle, Button as MagritteButton, useBreakpoint, IconColor } from '@hh.ru/magritte-ui';
import {
    HeartOutlinedSize16,
    HeartFilledSize16,
    HeartOutlinedSize24,
    HeartFilledSize24,
} from '@hh.ru/magritte-ui/icon';
import Button, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import IconDynamic from 'bloko/blocks/icon/IconDynamic';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { getDataQa } from 'Modules/VacancyToFavorite';
import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import useFavorite from 'src/hooks/useFavorite';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    add: 'hint.star',
    remove: 'hint.starMirror',
};

interface VacancyActionFavoriteProps {
    constructorBrandedVacancy?: boolean;
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
}

const VacancyActionFavorite: TranslatedComponent<VacancyActionFavoriteProps> = ({
    constructorBrandedVacancy,
    isRedesign,
    isMagritteVacancy,
    trls,
}) => {
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const employerId = useSelector(({ vacancyView }) => vacancyView.company.id);
    const isClosedVacancy = useSelector(({ vacancyView }) => vacancyView.closedForApplicants);
    const isBrandingPreview = useSelector(({ vacancyView }) => vacancyView.isBrandingPreview);
    const { isFavoriteVacancy, toggleIsFavoriteVacancy, isAvailableChangeFavoriteState } = useFavorite({
        vacancyId,
        employerId,
        isClosedVacancy,
        isSendUxFeedback: true,
    });
    const { isMobile } = useBreakpoint();

    if (!isAvailableChangeFavoriteState && !isBrandingPreview) {
        return null;
    }
    const renderIcon = () => {
        let iconColor: IconColor = constructorBrandedVacancy ? 'contrast' : 'secondary';
        if (isFavoriteVacancy) {
            iconColor = 'accent';
        }
        return (
            <BlokoIconReplaceContainer>
                {isFavoriteVacancy ? (
                    <HeartFilledSize16 initialColor={iconColor} data-qa="applicant-vacancy__favorite-add" />
                ) : (
                    <HeartOutlinedSize16 initialColor={iconColor} data-qa="applicant-vacancy__favorite-add" />
                )}
            </BlokoIconReplaceContainer>
        );
    };
    const getKind = () => {
        if (constructorBrandedVacancy) {
            return ButtonKind.Inversed;
        }
        if (isRedesign) {
            return ButtonKind.Success;
        }
        return undefined;
    };
    const commonProps = {
        'data-qa': getDataQa(isFavoriteVacancy),
        onClick: isBrandingPreview ? undefined : toggleIsFavoriteVacancy,
        title: trls[isFavoriteVacancy ? TrlKeys.remove : TrlKeys.add],
        disabled: isBrandingPreview,
    };

    const renderMagritteButton = () => {
        const size = isMobile ? 'small' : 'medium';
        let style: ButtonStyle = 'accent';
        let icon: JSX.Element;
        if (isFavoriteVacancy) {
            icon = isMobile ? (
                <HeartFilledSize16 initialColor="negative" />
            ) : (
                <HeartFilledSize24 initialColor="negative" />
            );
            style = 'negative';
        } else {
            icon = isMobile ? <HeartOutlinedSize16 /> : <HeartOutlinedSize24 />;
        }

        return (
            <MagritteButton
                style={style}
                mode="secondary"
                size={size}
                hideLabel
                aria-label={commonProps.title}
                icon={icon}
                {...commonProps}
            />
        );
    };

    const renderBlokoButton = () => (
        <IconDynamic>
            {isRedesign ? (
                <button
                    className="vacancy-action-favorite-redesign vacancy-action-favorite-redesign_appearance-outlined"
                    {...commonProps}
                >
                    <span
                        className={classnames('vacancy-action-favorite-icon-redesign', {
                            'vacancy-action-favorite-icon-redesign_checked': isFavoriteVacancy,
                            'vacancy-action-favorite-icon-redesign_disabled': isBrandingPreview,
                        })}
                    />
                </button>
            ) : (
                <Button appearance={ButtonAppearance.Outlined} kind={getKind()} icon={renderIcon()} {...commonProps} />
            )}
        </IconDynamic>
    );

    return <span className="vacancy-action">{isMagritteVacancy ? renderMagritteButton() : renderBlokoButton()}</span>;
};

export default translation(VacancyActionFavorite);
