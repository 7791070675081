import { useDispatch } from 'react-redux';

import { useElementShown } from '@hh.ru/analytics-js';
import showContactsOfferAcceptButtonClick, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/common/vacancy/show_contacts_offer_accept_button_click';
import showContactsOfferArticleButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy/show_contacts_offer_article_button_click';
import showContactsOfferCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy/show_contacts_offer_close_button_click';
import showContactsOfferElementShown from '@hh.ru/analytics-js-events/build/xhh/common/vacancy/show_contacts_offer_element_shown';
import { CrossOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { H2 } from 'bloko/blocks/header';
import { IconLink } from 'bloko/blocks/icon';
import Link from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

interface VacancyContactsAnonymousAgreementProps {
    onClose?: () => void;
    hideAgreement: () => void;
    vacancyId: number;
}

const TrlKeys = {
    title: 'anonymous.vacancy.contacts.title',
    message: 'anonymous.vacancy.contacts.message',
    link: 'anonymous.vacancy.contacts.agreement',
    accept: 'anonymous.vacancy.contacts.accept',
};

const anonymousAgreementAcceptedAction = makeSetStoreField('anonymousAgreementAccepted');

const VacancyContactsAnonymousAgreement: TranslatedComponent<VacancyContactsAnonymousAgreementProps> = ({
    onClose,
    hideAgreement,
    vacancyId,
    trls,
}) => {
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource as HhtmSource);

    const anonymousAgreementAnalyticsParams = { hhtmSource, vacancyId };
    const showAgreementRef = useElementShown(showContactsOfferElementShown, anonymousAgreementAnalyticsParams);
    const dispatch = useDispatch();

    const anonymousAcceptAgreement = () => {
        void fetcher.put('/shards/user_agreements/anonymous_agreement').finally(() => {
            hideAgreement();
            showContactsOfferAcceptButtonClick(anonymousAgreementAnalyticsParams);
            dispatch(anonymousAgreementAcceptedAction(true));
        });
    };

    return (
        <div ref={showAgreementRef} className="vacancy-contacts-call-tracking-anonymous-agreement">
            {onClose && (
                <span className="vacancy-contacts-call-tracking-anonymous-agreement__close">
                    <IconLink
                        type="button"
                        Element="button"
                        onClick={() => {
                            onClose();
                            showContactsOfferCloseButtonClick(anonymousAgreementAnalyticsParams);
                        }}
                    >
                        <BlokoIconReplaceContainer>
                            <CrossOutlinedSize16 initialColor="secondary" />
                        </BlokoIconReplaceContainer>
                    </IconLink>
                </span>
            )}
            <H2>{trls[TrlKeys.title]}</H2>
            <VSpacing base={6} />
            <Text size={TextSize.Small}>
                {trls[TrlKeys.message]}{' '}
                <Link
                    href={'/account/agreement'}
                    target="_blank"
                    onClick={() => showContactsOfferArticleButtonClick(anonymousAgreementAnalyticsParams)}
                >
                    {trls[TrlKeys.link]}
                </Link>
            </Text>
            <VSpacing base={6} />
            <Button kind={ButtonKind.Primary} stretched onClick={anonymousAcceptAgreement}>
                {trls[TrlKeys.accept]}
            </Button>
        </div>
    );
};

export default translation(VacancyContactsAnonymousAgreement);
