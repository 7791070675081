import classnames from 'classnames';

import { Text, Card, CardStyle, useBreakpoint } from '@hh.ru/magritte-ui';
import { InfoCircleOutlinedSize16, InfoCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useResumeIdResponseStatuses from 'src/hooks/useResumeIdResponseStatuses';
import { useSelector } from 'src/hooks/useSelector';

import RespondButton from 'src/components/VacancyView/ResponseStatus/RespondButton';

import styles from './response-status.less';

const TrlKeys = {
    responded: 'vacancy.view.topic.status.responded',
    respondedShort: 'vacancy.view.topic.status.responded.short',
    discarded: 'vacancy.view.topic.status.discard',
    invited: 'vacancy.view.topic.status.invitation',
};

interface ResponseStatusProps {
    isMagritteVacancy?: boolean;
    headerMode?: boolean;
}

function getStatusInfo({
    isInvited,
    isDiscarded,
    isMagritteVacancy = false,
}: {
    isInvited: boolean;
    isDiscarded: boolean;
    isMagritteVacancy?: boolean;
}) {
    let statusTrl = isMagritteVacancy ? TrlKeys.respondedShort : TrlKeys.responded;
    let color: 'primary' | 'positive-secondary' | 'warning-secondary' = 'primary';
    let cardStyle: CardStyle = 'secondary';

    if (isInvited) {
        statusTrl = TrlKeys.invited;
        color = 'positive-secondary';
        cardStyle = 'positive-secondary';
    } else if (isDiscarded) {
        statusTrl = TrlKeys.discarded;
        color = 'warning-secondary';
        cardStyle = 'warning-secondary';
    }

    return {
        statusTrl,
        color,
        cardStyle,
    };
}

const ResponseStatus: TranslatedComponent<ResponseStatusProps> = ({ trls, isMagritteVacancy, headerMode }) => {
    const { vacancyId } = useSelector((state) => state.vacancyView);
    const { employerId } = useSelector((state) => state.vacancyView);
    const responseStatuses = useSelector((state) => state.applicantVacancyResponseStatuses);
    const resumeIdResponseStatuses = useResumeIdResponseStatuses(vacancyId);
    const { isMobile } = useBreakpoint();

    if (!vacancyId) {
        return null;
    }

    const responseStatus = responseStatuses[vacancyId];
    const hasUsedResumes = Boolean(responseStatus?.usedResumeIds?.length);

    if (!hasUsedResumes) {
        return null;
    }

    const isInvited = Boolean(resumeIdResponseStatuses?.invited?.length);
    const isDiscarded = Boolean(resumeIdResponseStatuses?.discarded?.length);
    const hasUnusedResumes = Boolean(responseStatus?.unusedResumeIds?.length);
    const hasHiddenResumes = Boolean(responseStatus?.hiddenResumeIds?.length);
    const showRespondButtonAgain = hasUnusedResumes || hasHiddenResumes;
    const { statusTrl, color, cardStyle } = getStatusInfo({ isInvited, isDiscarded, isMagritteVacancy });

    if (isMagritteVacancy) {
        return (
            <Card stretched style={cardStyle} padding={12} borderRadius={12}>
                <div className={styles.cardContent}>
                    {isMobile ? (
                        <InfoCircleOutlinedSize16 initialColor={color} />
                    ) : (
                        <InfoCircleOutlinedSize24 initialColor={color} />
                    )}
                    <Text typography={isMobile ? 'label-3-regular' : 'label-2-regular'} style={color}>
                        {trls[statusTrl]}
                    </Text>
                </div>
            </Card>
        );
    }

    return (
        <div
            className={classnames('vacancy-response', {
                'vacancy-response_invited': isInvited,
                'vacancy-response_header-mode': headerMode,
            })}
        >
            <div className="vacancy-response__already-replied">{trls[statusTrl]}</div>
            {showRespondButtonAgain && <RespondButton vacancyId={vacancyId} employerId={employerId} />}
        </div>
    );
};

export default translation(ResponseStatus);
