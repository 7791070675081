import { FC, useCallback, useEffect, useRef, useState, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { ChevronLeftOutlinedSize24, ChevronRightOutlinedSize24, IconDynamic } from '@hh.ru/magritte-ui/icon';
import VSpacing from 'bloko/blocks/vSpacing';
import debounce from 'bloko/common/debounce';
import useResize from 'bloko/common/hooks/useResize';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';

import style from './employer-cards.less';

const DEFAULT_SCROLL_STEP = 150;
const TRACKING_INTERVAL_MS = 200;

const EMPLOYER_VIEW_SCROLL_STEP = 196;
const EMPLOYER_VIEW_TRACKING_INTERVAL_MS = 100;

const Scroller: FC<PropsWithChildren & { isEmployerView?: boolean }> = ({ children, isEmployerView = false }) => {
    const scrollboxRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<HTMLDivElement>(null);
    const [leftControlVisible, setLeftControlVisible] = useState(false);
    const [rightControlVisible, setRightControlVisible] = useState(false);

    const setScroll = useCallback((delta: number) => {
        if (scrollboxRef.current) {
            scrollboxRef.current.scrollBy({ top: 0, left: delta });
        }
    }, []);

    const recalculateControls: () => void = useCallback(() => {
        if (!scrollboxRef.current || !sliderRef.current) {
            return;
        }

        const sliderWidth = scrollboxRef.current.scrollWidth;
        const wrapperWidth = scrollboxRef.current.getBoundingClientRect().width;
        const currentScroll = scrollboxRef.current.scrollLeft;

        if (wrapperWidth >= sliderWidth) {
            setLeftControlVisible(false);
            setRightControlVisible(false);
            return;
        }

        setLeftControlVisible(currentScroll > 0);
        setRightControlVisible(sliderWidth - currentScroll > wrapperWidth);
    }, [scrollboxRef, sliderRef]);

    useResize(
        debounce(recalculateControls, isEmployerView ? EMPLOYER_VIEW_TRACKING_INTERVAL_MS : TRACKING_INTERVAL_MS)
    );

    useEffect(() => {
        if (!scrollboxRef.current) {
            return () => {};
        }

        const currentScrollbox = scrollboxRef.current;
        const debouncedScroll = debounce(
            recalculateControls,
            isEmployerView ? EMPLOYER_VIEW_TRACKING_INTERVAL_MS : TRACKING_INTERVAL_MS
        );
        currentScrollbox.addEventListener('scroll', debouncedScroll);

        return () => {
            currentScrollbox.removeEventListener('scroll', debouncedScroll);
        };
    }, [isEmployerView, recalculateControls, scrollboxRef]);

    useEffect(() => {
        recalculateControls();
    }, [recalculateControls]);

    return (
        <>
            <VSpacing base={2} />
            <div
                className={classnames(style.employerCardsWrapper, {
                    [style.employerCardsWrapperEmployerView]: isEmployerView,
                })}
            >
                <div
                    onClick={() => setScroll(isEmployerView ? -EMPLOYER_VIEW_SCROLL_STEP : -DEFAULT_SCROLL_STEP)}
                    className={classnames(style.employerCardsSliderControlLeft, {
                        [style.employerCardsSliderControlLeftShown]: leftControlVisible,
                        [style.employerCardsSliderControlLeftEmployerView]: isEmployerView,
                    })}
                >
                    {isEmployerView ? (
                        <BlokoIconReplaceContainer>
                            <ChevronLeftOutlinedSize24 initialColor="secondary" />
                        </BlokoIconReplaceContainer>
                    ) : (
                        <IconDynamic>
                            <span>
                                <BlokoIconReplaceContainer>
                                    <ChevronLeftOutlinedSize24 initialColor="accent" highlightedColor="secondary" />
                                </BlokoIconReplaceContainer>
                            </span>
                        </IconDynamic>
                    )}
                </div>
                {isEmployerView && <div className={style.employerCardsSliderControlLeftGrad} />}
                <div
                    onClick={() => setScroll(isEmployerView ? EMPLOYER_VIEW_SCROLL_STEP : DEFAULT_SCROLL_STEP)}
                    className={classnames(style.employerCardsSliderControlRight, {
                        [style.employerCardsSliderControlRightShown]: rightControlVisible,
                        [style.employerCardsSliderControlRightEmployerView]: isEmployerView,
                    })}
                >
                    {isEmployerView ? (
                        <BlokoIconReplaceContainer>
                            <ChevronRightOutlinedSize24 initialColor="secondary" />
                        </BlokoIconReplaceContainer>
                    ) : (
                        <IconDynamic>
                            <span>
                                <BlokoIconReplaceContainer>
                                    <ChevronRightOutlinedSize24 initialColor="accent" highlightedColor="secondary" />
                                </BlokoIconReplaceContainer>
                            </span>
                        </IconDynamic>
                    )}
                </div>
                {isEmployerView && <div className={style.employerCardsSliderControlRightGrad} />}
                <div
                    ref={scrollboxRef}
                    className={classnames(style.employerCardsScrollbox, {
                        [style.employerCardsScrollboxEmployerView]: isEmployerView,
                    })}
                >
                    <div
                        ref={sliderRef}
                        className={classnames(style.employerCardsSlider, {
                            [style.employerCardsSliderEmployerView]: isEmployerView,
                        })}
                    >
                        {children}
                    </div>
                </div>
            </div>
            {!isEmployerView && <VSpacing base={5} />}
        </>
    );
};

export default Scroller;
