import { useRef } from 'react';

import vacancyViewContactsExternal, {
    Category,
} from '@hh.ru/analytics-js-events/build/xhh/common/vacancy/vacancy_view_contacts';

import SendHHAnalytics, { SendKardinalAnalytics } from 'HH/SendHHAnalytics';
import { UserType } from 'src/models/userType';

type SendViewContactsAnalytics = (params: { vacancyId?: number; employerId?: number; userType: UserType }) => void;
export default function useContactsAnalytics({ place }: { place: string }): SendViewContactsAnalytics {
    const wasSentAnalytics = useRef(false);

    const sendViewContactsAnalytics: SendViewContactsAnalytics = ({ vacancyId, employerId, userType }) => {
        vacancyViewContactsExternal({ category: userType as Category });
        if (!wasSentAnalytics.current) {
            void SendHHAnalytics({
                data: {
                    goal: 'view_contacts',
                    vacancy_id: String(vacancyId),
                },
            });
            void SendKardinalAnalytics({
                goal: 'view_contacts',
                data: {
                    vacancy_id: String(vacancyId),
                    employer_id: String(employerId),
                    place,
                },
            });
            wasSentAnalytics.current = true;
        }
    };

    return sendViewContactsAnalytics;
}
