import { MouseEvent } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyResponseLink from 'src/components/VacancyResponseLink';
import translation from 'src/components/translation';

const TrlKeys = {
    title: 'button.placeOtherResponse',
    titleShort: 'button.placeOtherResponse.short',
};

interface RespondButtonProps {
    vacancyId: number;
    employerId?: number;
}

const RespondButton: TranslatedComponent<RespondButtonProps> = ({ trls, vacancyId, employerId }) => {
    const renderLink = (onClick: (event?: MouseEvent) => void) => {
        const props = {
            Element: SPALink,
            rel: 'nofollow',
            to: `/applicant/vacancy_response?vacancyId=${vacancyId}${employerId ? `&employerId=${employerId}` : ''}`,
            'data-qa': 'vacancy-response-link-top-again',
            onClick,
        };

        return <BlokoLink {...props}>{trls[TrlKeys.title]}</BlokoLink>;
    };

    return (
        <VacancyResponseLink
            vacancyId={vacancyId}
            employerId={employerId}
            render={renderLink}
            place="vacancy_top_button"
        />
    );
};

export default translation(RespondButton);
