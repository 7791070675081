import { ComponentType, ReactNode, FC } from 'react';

import addressMapButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/address_map_button_click';
import { Text, Link } from '@hh.ru/magritte-ui';
import { LocationOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MetroList from 'src/components/MetroList';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface DefaultWrapperComponentProps {
    children: ReactNode;
    archived?: boolean;
    vacancyLink: string;
    isRedesign?: boolean;
}

const DefaultWrapperComponent: FC<DefaultWrapperComponentProps> = ({ children, vacancyLink, archived, isRedesign }) => {
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const address = useSelector(({ vacancyView }) => vacancyView.address);
    const mapDisabled = useSelector(({ vacancyView }) => vacancyView.mapDisabled);
    const mapDataAvailable = !!address?.mapData?.points;

    const onClick = () => {
        if (!vacancyId) {
            return;
        }
        addressMapButtonClick({ hhtmSourceLabel: 'vacancy_top_button', vacancyId });
    };

    if (mapDataAvailable && !mapDisabled && !archived) {
        return (
            <Link
                Element={SPALink}
                to={`${vacancyLink}${vacancyId || ''}`}
                target="_blank"
                data-qa="vacancy-view-link-location"
                style="neutral"
                onClick={onClick}
            >
                {!isRedesign && (
                    <>
                        <LocationOutlinedSize16 initialColor="primary" />
                        {NON_BREAKING_SPACE}
                    </>
                )}
                {children}
            </Link>
        );
    }
    return (
        <Text>
            <p data-qa="vacancy-view-location">{children}</p>
        </Text>
    );
};

const TrlKeys = {
    noArea: 'vacancy.noArea',
    manyArea: 'vacancy.manyArea',
};

interface VacancyCompanyAddressProps {
    WrapperComponent?: ComponentType<DefaultWrapperComponentProps>;
    isRedesign?: boolean;
}

const VacancyCompanyAddress: TranslatedComponent<VacancyCompanyAddressProps> = ({
    WrapperComponent = DefaultWrapperComponent,
    trls,
    isRedesign,
}) => {
    const area = useSelector((state) => state.vacancyView.area);
    const address = useSelector((state) => state.vacancyView.address);
    const multi = useSelector((state) => state.vacancyView.multi);
    const archived = useSelector((state) => state.vacancyView?.status?.archived);
    const vacancyLink = useSelector((state) => state.vacancyView.vacancyOnMapLink);
    const metroList = address?.metroStations?.metro;

    const renderAddressContent = () => {
        if (area?.name && !multi && !address?.city && !address?.street && !address?.mapData) {
            if (area.name) {
                return (
                    <>
                        {area.name}
                        {metroList && (
                            <>
                                {', '}
                                <MetroList metroList={metroList} />
                            </>
                        )}
                    </>
                );
            }
            return trls[TrlKeys.noArea];
        }

        if (address?.city || address?.street || address?.mapData) {
            if (address.city) {
                let addressDetail;
                if (address.street) {
                    addressDetail = `, ${[address.street, address.building].filter(Boolean).join(', ')}`;
                } else if (address.rawAddress) {
                    // старая логика, сейчас rawAddress deprecated на бекенде,
                    // оставляю для сохранения вывода адресов, которые были заведены давно
                    addressDetail = `, ${address.rawAddress}`;
                } else if (address.building) {
                    // случай когда в city пришло название деревни, а в building - дом
                    addressDetail = `, ${address.building}`;
                }

                return (
                    <span data-qa="vacancy-view-raw-address">
                        {isRedesign && address.city}
                        {metroList && (
                            <>
                                {isRedesign && ', '}
                                <MetroList metroList={metroList} />
                                {!isRedesign && ', '}
                            </>
                        )}
                        {!isRedesign && address.city}
                        {addressDetail}
                    </span>
                );
            }
            return <span data-qa="vacancy-view-raw-address">{address.rawAddress}</span>;
        }

        if (multi) {
            return trls[TrlKeys.manyArea];
        }

        return null;
    };
    const addressContent = renderAddressContent();
    return addressContent ? (
        <WrapperComponent vacancyLink={vacancyLink} archived={archived} isRedesign={isRedesign}>
            {addressContent}
        </WrapperComponent>
    ) : null;
};

export default translation(VacancyCompanyAddress);
