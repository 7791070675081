import { SPALink } from '@hh.ru/redux-spa-middleware';
import Text, { TextSize } from 'bloko/blocks/text';

import translation from 'src/components/translation';

const ReferralLink = ({ trls }) => (
    <div className="clickme__link-wrapper">
        <SPALink
            to="https://clickme.hh.ru/new/dashboard?utm_source=block_referral"
            rel="noopener noreferrer"
            className="clickme__link"
            target="_blank"
        >
            <Text size={TextSize.Small}>{trls[ReferralLink.trls.link]}</Text>
        </SPALink>
    </div>
);

ReferralLink.trls = {
    link: 'clickme.adv.here',
};

export default translation(ReferralLink);
