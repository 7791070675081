import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';
import NotVerifiedEmployerBanner from 'src/components/EmployerVerification/NotVerifiedEmployerBanner';
import {
    useEmployerVerificationState,
    BannerType,
} from 'src/components/EmployerVerification/useEmployerVerificationState';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import EmployerCards from 'src/components/VacancyView/EmployerCards';
import VacancyCompanyAddress from 'src/components/VacancyView/VacancyCompanyAddress';
import VacancyCompanyLogo from 'src/components/VacancyView/VacancyCompanyLogo';
import VacancyCompanyNameMagritte from 'src/components/VacancyView/VacancyCompanyNameMagritte';
import useCompanyLogo from 'src/components/VacancyView/hooks/useCompanyLogo';
import usePreview from 'src/components/VacancyView/hooks/usePreview';

const ReviewWidgetWrapper: FC<PropsWithChildren> = ({ children }) => (
    <>
        <VSpacing base={4} />
        {children}
    </>
);

interface VacancyCompanyProps {
    constructorBrandedVacancy?: boolean;
    hasWrapper?: boolean;
}

const VacancyCompany: FC<VacancyCompanyProps> = ({ constructorBrandedVacancy, hasWrapper = true }) => {
    const { id: companyId, badges } = useSelector(({ vacancyView }) => vacancyView.company);
    const trusted = useSelector(({ vacancyView }) => vacancyView.company['@trusted']);
    const companyLogo = useCompanyLogo();
    const employerId = useSelector(({ employerId }) => employerId);
    const isVacancyOwner = Number(companyId) === Number(employerId);
    const isPrint = useSelector(({ router }) => !!router.location.query.print);

    const breakpoint = useBreakpoint(Breakpoint.L);
    const isZarplata = useIsZarplataPlatform();
    const showCompanyLogo = !constructorBrandedVacancy || (constructorBrandedVacancy && breakpoint === Breakpoint.XS);
    const showReviewsWidget =
        trusted && (!constructorBrandedVacancy || (constructorBrandedVacancy && breakpoint !== Breakpoint.XS));
    const request = useSelector((state) => state.request);
    const isPreview = usePreview(request.url);
    const { isUnverifiedHhRuEmployer, shouldShowIcon, shouldShowBanner } = useEmployerVerificationState(
        isVacancyOwner && !isPrint,
        isPreview && isVacancyOwner && !isPrint
    );

    const vacancyCompanyContent = (
        <div className="vacancy-company-wrapper">
            <div
                className={classnames('vacancy-company', {
                    'vacancy-company_branded-with-logo': constructorBrandedVacancy && companyLogo,
                    'vacancy-company_with-logo': !constructorBrandedVacancy && companyLogo,
                })}
                data-qa="vacancy-company"
            >
                <div className="vacancy-company-top">
                    <div className="vacancy-company-top-details">
                        <VacancyCompanyNameMagritte
                            constructorBrandedVacancy={constructorBrandedVacancy}
                            shouldShowTrustedEmployerCheck={!shouldShowIcon}
                            shouldShowVerificationIcon={shouldShowIcon}
                        />
                        {isUnverifiedHhRuEmployer && shouldShowBanner && (
                            <>
                                <VSpacing base={5} />
                                <NotVerifiedEmployerBanner bannerType={BannerType.VacancyPreview} />
                                <VSpacing base={5} />
                            </>
                        )}
                        {showReviewsWidget && (
                            <>
                                <EmployerReviewsWidgetSmall Wrapper={ReviewWidgetWrapper} />
                                <VSpacing base={4} />
                            </>
                        )}
                        <VacancyCompanyAddress
                            WrapperComponent={
                                isZarplata
                                    ? ({ children }) => (
                                          <Text Element="span" data-qa="vacancy-view-location">
                                              {children}
                                          </Text>
                                      )
                                    : undefined
                            }
                        />
                    </div>
                    {showCompanyLogo && <VacancyCompanyLogo constructorBrandedVacancy={constructorBrandedVacancy} />}
                </div>
                {badges && <VSpacing base={6} />}
                <EmployerCards companyId={companyId} badges={badges} isEmployerView />
                <VSpacing base={6} />
            </div>
        </div>
    );

    if (!hasWrapper) {
        return <>{vacancyCompanyContent}</>;
    }

    return (
        <>
            <ColumnsRow>
                <Column xs="4" s={constructorBrandedVacancy ? '6' : '8'} m="8" l="10">
                    {vacancyCompanyContent}
                </Column>
            </ColumnsRow>
        </>
    );
};

export default VacancyCompany;
