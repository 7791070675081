import { FC, MutableRefObject, RefObject, useEffect, useState } from 'react';

import contactsCloseBtnClick from '@hh.ru/analytics-js-events/build/xhh/applicant/contacts/contacts_close_button_click';
import {
    BottomSheet,
    BottomSheetFooter,
    Drop,
    useBreakpoint,
    NavigationBar,
    Action,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import DropDown from 'bloko/blocks/drop/Down/Click';

import fetchResponseType from 'src/api/contacts/fetchResponseType';
import useExperiment from 'src/hooks/useExperiment';
import usePrintDetect from 'src/hooks/usePrintDetect';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { VacancyChatInfoType } from 'src/models/vacancyChatInfo';

import ContactChat from 'src/components/VacancyView/ContactChat';
import VacancyContactsDropContent from 'src/components/VacancyView/VacancyContactsDropContent';

interface VacancyContactsDropProps {
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    children: JSX.Element;
    show?: boolean;
    place: string;
    activatorRef: MutableRefObject<null>;
    onClose?: VoidFunction;
}

const VacancyContactsDrop: FC<VacancyContactsDropProps> = ({
    vacancyBodyFooterNodeRef,
    show,
    children,
    place,
    activatorRef,
    onClose,
}) => {
    const [chatButtonInfo, setChatButtonInfo] = useState<VacancyChatInfoType | null>(null);
    const [chatButtonFetching, setChatButtonFetching] = useState(true);
    const userType = useSelector((state) => state.userType);
    const { vacancyId, contactInfo, address } = useSelector((state) => state.vacancyView);
    const employerId = useSelector((state) => state.vacancyView.company.id);
    const employerName = useSelector((state) => state.vacancyView.company.visibleName);
    const chatWritePossibility = useSelector((state) =>
        vacancyId ? state.applicantVacancyResponseStatuses[vacancyId]?.shortVacancy?.chatWritePossibility : undefined
    );
    const isPrinting = usePrintDetect();
    const isAnonymous = userType === UserType.Anonymous;
    const isContactsRedesignExpBEnabled = useExperiment('web_redesign_vacancy_contacts_b');
    const isContactsRedesignExpCEnabled = useExperiment('web_redesign_vacancy_contacts_c');
    const isContactsRedesignExpEnabled = isContactsRedesignExpBEnabled || isContactsRedesignExpCEnabled;
    const isVacancyChatEnable = chatWritePossibility !== 'DISABLED';
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        const fetchData = async () => {
            setChatButtonFetching(true);
            if (typeof vacancyId !== 'number' || userType === UserType.Employer) {
                setChatButtonFetching(false);
                return;
            }
            try {
                const chatInfo = await fetchResponseType(vacancyId);
                setChatButtonInfo(chatInfo);
            } finally {
                setChatButtonFetching(false);
            }
        };

        if (!isAnonymous) {
            void fetchData();
        } else {
            setChatButtonFetching(false);
        }
    }, [isAnonymous, userType, vacancyId]);

    if (typeof vacancyId !== 'number') {
        return null;
    }

    const isChatAvailable = chatButtonInfo && isVacancyChatEnable && employerId;

    const footerContentRedesigned = (
        <ContactChat
            chatButtonInfo={chatButtonInfo!}
            vacancyId={vacancyId}
            employerId={employerId!}
            onClick={() => {
                onClose?.();
            }}
            isMagritteExp
            isContactsRedesignExp
        />
    );

    const dropContentRedesigned = (
        <VacancyContactsDropContent
            chatButtonInfo={chatButtonInfo}
            loading={chatButtonFetching}
            onClose={() => {
                onClose?.();
            }}
            address={address ? address.displayName : undefined}
            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
            vacancyId={vacancyId}
            contactInfo={contactInfo}
            isAnonymous={isAnonymous}
            employerId={employerId}
            chatWritePossibility={chatWritePossibility}
            isNameHidden={isMobile}
        />
    );

    if (isContactsRedesignExpEnabled) {
        return (
            <>
                {children}
                {isMobile ? (
                    <BottomSheet
                        visible={!!show}
                        header={
                            <NavigationBar
                                title={contactInfo?.fio}
                                subtitle={employerName}
                                right={
                                    <Action
                                        icon={CrossOutlinedSize24}
                                        onClick={() => {
                                            onClose?.();
                                            contactsCloseBtnClick({
                                                vacancyId: String(vacancyId),
                                            });
                                        }}
                                    />
                                }
                            />
                        }
                        footer={isChatAvailable && <BottomSheetFooter>{footerContentRedesigned}</BottomSheetFooter>}
                        onClose={() => {
                            onClose?.();
                        }}
                        interceptClickHandlers={false}
                    >
                        {dropContentRedesigned}
                        {!isChatAvailable && <VSpacing default={12} />}
                    </BottomSheet>
                ) : (
                    <Drop
                        data-qa={'vacancy-contacts-drop'}
                        placement={['bottom-left', 'right-bottom']}
                        visible={show}
                        activatorRef={activatorRef}
                        onClose={() => {
                            onClose?.();
                        }}
                        width={380}
                        footer={isChatAvailable && footerContentRedesigned}
                    >
                        {dropContentRedesigned}
                    </Drop>
                )}
            </>
        );
    }

    if (isPrinting) {
        if (!show || place === 'top') {
            return children;
        }
        return (
            <VacancyContactsDropContent
                loading={chatButtonFetching}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                vacancyId={vacancyId}
                contactInfo={contactInfo}
                isAnonymous={isAnonymous}
            />
        );
    }

    return (
        <DropDown
            show={show}
            showCloseButton={!contactInfo?.callTrackingEnabled}
            flexible
            render={(closeDrop) => (
                <VacancyContactsDropContent
                    chatButtonInfo={chatButtonInfo}
                    loading={chatButtonFetching}
                    onClose={closeDrop}
                    address={address ? address.displayName : undefined}
                    vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                    vacancyId={vacancyId}
                    contactInfo={contactInfo}
                    isAnonymous={isAnonymous}
                    employerId={employerId}
                    chatWritePossibility={chatWritePossibility}
                />
            )}
            activatorRef={activatorRef}
        >
            {children}
        </DropDown>
    );
};

export default VacancyContactsDrop;
