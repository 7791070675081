import { FC } from 'react';

import { Card, HSpacingContainer } from '@hh.ru/magritte-ui';

import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';
import { useSelector } from 'src/hooks/useSelector';

import EmployerCards from 'src/components/VacancyView/EmployerCards';
import VacancyCompanyLogo from 'src/components/VacancyView/VacancyCompanyLogo';
import VacancyCompanyName from 'src/components/VacancyView/VacancyCompanyName';

const VacancyCompanyRedesigned: FC = () => {
    const companyId = useSelector((state) => state.vacancyView.company.id);
    const badges = useSelector((state) => state.vacancyView.company.badges);
    const trusted = useSelector((state) => state.vacancyView.company['@trusted']);

    return (
        <Card stretched padding={24} borderRadius={24} borderWidth="default" data-qa="vacancy-company">
            <HSpacingContainer default={16}>
                <VacancyCompanyLogo isRedesign isMagritteVacancy />
                <div>
                    <VacancyCompanyName isRedesign isMagritteVacancy />
                    {trusted && (
                        <div className="noprint">
                            <EmployerReviewsWidgetSmall isRedesign isMagritteVacancy />
                        </div>
                    )}
                </div>
            </HSpacingContainer>
            <EmployerCards companyId={companyId} badges={badges} isMagritteVacancy />
        </Card>
    );
};

export default VacancyCompanyRedesigned;
