import { FC, MouseEventHandler, useCallback } from 'react';
import classnames from 'classnames';

import { Avatar } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import VSpacing from 'bloko/blocks/vSpacing';

import SendHHAnalytics from 'HH/SendHHAnalytics';
import CdnImg from 'src/components/CdnImg';
import { useSelector } from 'src/hooks/useSelector';
import { Platform } from 'src/models/locale.types';

import useCompanyLogo from 'src/components/VacancyView/hooks/useCompanyLogo';
import usePreview from 'src/components/VacancyView/hooks/usePreview';

interface VacancyCompanyLogoProps {
    constructorBrandedVacancy?: boolean;
    isMagritteVacancy?: boolean;
    isRedesign?: boolean;
    noPrint?: boolean;
}

const VacancyCompanyLogo: FC<VacancyCompanyLogoProps> = ({
    constructorBrandedVacancy,
    isRedesign,
    isMagritteVacancy,
    noPrint,
}) => {
    const companyId = useSelector((state) => state.vacancyView.company.id);
    const companyName = useSelector((state) => state.vacancyView.company.name);
    const request = useSelector((state) => state.request);
    const companyLogo = useCompanyLogo();
    const isPreview = usePreview(request.url);
    const isZpCrossposting = useSelector(
        (state) =>
            state.locale.platform === Platform.Zarplata &&
            !!state.vacancyView.vacancyProperties?.calculatedStates?.ZP?.crosspostedFrom
    );

    const logoOnClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            if (isPreview) {
                event.preventDefault();
                return;
            }
            void SendHHAnalytics({ data: { goal: 'vacancy-company-logo' } });
        },
        [isPreview]
    );

    if (!companyLogo) {
        return null;
    }

    const companyUrl = `/employer/${companyId || ''}`;
    const companyLogoUrl = companyLogo['@url'];

    if (isMagritteVacancy) {
        const avatar = (
            <div className="vacancy-company-avatar-wrapper">
                <Avatar
                    mode="image"
                    image={companyLogoUrl}
                    placeholder="city"
                    fallbackMode="placeholder"
                    size={48}
                    shapeCircle
                    aria-label={companyName || ''}
                    data-qa="vacancy-company-logo"
                />
            </div>
        );

        return isZpCrossposting ? (
            avatar
        ) : (
            <SPALink to={companyUrl} onClick={logoOnClick}>
                {avatar}
            </SPALink>
        );
    }

    const logo = (
        <CdnImg
            loading="lazy"
            path={companyLogoUrl}
            alt={companyName}
            className={
                isRedesign
                    ? 'vacancy-company-logo-image-redesigned'
                    : classnames('vacancy-company-logo__image', {
                          'vacancy-company-logo__image_vacancyconstructor': constructorBrandedVacancy,
                      })
            }
        />
    );

    return (
        <>
            <div
                className={
                    isRedesign
                        ? 'vacancy-company-logo-redesigned'
                        : classnames('vacancy-company-logo', {
                              'vacancy-company-logo_vacancyconstructor': constructorBrandedVacancy,
                              'vacancy-company-logo_no-print': noPrint,
                          })
                }
            >
                {isZpCrossposting ? (
                    logo
                ) : (
                    <SPALink to={companyUrl} onClick={logoOnClick}>
                        {logo}
                    </SPALink>
                )}
            </div>
            {isRedesign && <VSpacing base={4} />}
        </>
    );
};

export default VacancyCompanyLogo;
