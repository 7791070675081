import { MutableRefObject, useMemo } from 'react';

import useBreakpoint from 'bloko/common/hooks/useBreakpoint';

import { AppStore } from 'src/app/store';
import EmployerReviewsWidgetBig from 'src/components/EmployerReviews/WidgetBig';
import employerReviewsWidgetCheckRender from 'src/components/EmployerReviews/WidgetBig/checkRender';
import VacancyApplicantRespondedInfo, {
    checkVacancyApplicantRespondedInfoRender,
} from 'src/components/VacancyView/VacancyApplicantRespondedInfo';
import Top from 'src/components/VacancyView/VacancyBlock/Top';
import VacancyContacts from 'src/components/VacancyView/VacancyContacts';
import checkVacancyContactsRender from 'src/components/VacancyView/VacancyContacts/checkRender';
import VacancyResponseQuestions, {
    checkVacancyResponseQuestionsRender,
} from 'src/components/VacancyView/VacancyResponseQuestions';

import BetweenSections, {
    checkBetweenSectionsRender,
} from 'src/components/VacancyView/VacancyConstructor/external/BetweenSections';
import EmployerViewTop from 'src/components/VacancyView/VacancyConstructor/external/EmployerViewTop';
import FirstSectionFooter, {
    checkFirstSectionFooterRender,
} from 'src/components/VacancyView/VacancyConstructor/external/FirstSectionFooter';
import VacancyBody from 'src/components/VacancyView/VacancyConstructor/external/VacancyBody';
import { MapperElementConfig } from 'src/components/VacancyView/VacancyConstructor/external/externalWidgets.types';

export enum WidgetName {
    EmployerReviewsWidgetBig = 'EmployerReviewsWidgetBig',
    ApplicantRespondedInfo = 'ApplicantRespondedInfo',
    VacancyContacts = 'VacancyContacts',
    BetweenSections = 'BetweenSections',
    VacancyResponseQuestions = 'VacancyResponseQuestions',
    FirstSectionFooter = 'FirstSectionFooter',
    VacancyBody = 'VacancyBody',
    VacancyBlockTop = 'VacancyBlockTop',
    EmployerViewTop = 'EmployerViewTop',
}

interface UseMapWidgetToConfigArg {
    vacancyBodyFooterNodeRef: MutableRefObject<HTMLDivElement>;
    contactsNodeRef: MutableRefObject<HTMLDivElement>;
    hasWrapper?: boolean;
    isRedesign: boolean;
    resumeFormNodeRef: MutableRefObject<HTMLDivElement>;
}

const useMapWidgetToConfig = ({
    isRedesign,
    vacancyBodyFooterNodeRef,
    contactsNodeRef,
    hasWrapper = false,
    resumeFormNodeRef,
}: UseMapWidgetToConfigArg): Record<WidgetName, MapperElementConfig> => {
    const breakpoint = useBreakpoint();
    return useMemo(
        () => ({
            [WidgetName.EmployerReviewsWidgetBig]: {
                Element: <EmployerReviewsWidgetBig isRedesign={isRedesign} hasWrapper={hasWrapper} />,
                checkRender: employerReviewsWidgetCheckRender,
            },
            [WidgetName.ApplicantRespondedInfo]: {
                Element: (
                    <VacancyApplicantRespondedInfo
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        isRedesign={isRedesign}
                    />
                ),
                checkRender: checkVacancyApplicantRespondedInfoRender,
            },
            [WidgetName.VacancyContacts]: {
                Element: (
                    <VacancyContacts
                        isRedesign={isRedesign}
                        blockRef={contactsNodeRef}
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        hasVacancySectionWraper={hasWrapper}
                    />
                ),
                checkRender: (state: AppStore) => checkVacancyContactsRender(state, breakpoint),
            },
            [WidgetName.BetweenSections]: {
                Element: (
                    <BetweenSections isRedesign={isRedesign} vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
                ),
                checkRender: checkBetweenSectionsRender,
            },
            [WidgetName.VacancyResponseQuestions]: {
                Element: (
                    <VacancyResponseQuestions
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        hasBottomGap={hasWrapper}
                    />
                ),
                checkRender: checkVacancyResponseQuestionsRender,
            },
            [WidgetName.FirstSectionFooter]: {
                Element: <FirstSectionFooter isRedesign={isRedesign} hasWrapper={hasWrapper} />,
                checkRender: checkFirstSectionFooterRender,
            },
            [WidgetName.VacancyBody]: {
                Element: <VacancyBody isRedesign={isRedesign} hasWrapper={hasWrapper} />,
            },
            [WidgetName.VacancyBlockTop]: {
                Element: (
                    <Top
                        hasWrapper
                        hasWrapperCardOnly
                        resumeFormNodeRef={resumeFormNodeRef}
                        contactsNodeRef={contactsNodeRef}
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                    />
                ),
            },
            [WidgetName.EmployerViewTop]: {
                Element: (
                    <EmployerViewTop
                        resumeFormNodeRef={resumeFormNodeRef}
                        contactsNodeRef={contactsNodeRef}
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        hasWrapper={hasWrapper}
                    />
                ),
            },
        }),
        [hasWrapper, isRedesign, contactsNodeRef, vacancyBodyFooterNodeRef, resumeFormNodeRef, breakpoint]
    );
};

export default useMapWidgetToConfig;

export const firstSectionList = [
    WidgetName.VacancyContacts,
    WidgetName.VacancyResponseQuestions,
    WidgetName.FirstSectionFooter,
];
export const secondSectionList = [WidgetName.EmployerReviewsWidgetBig, WidgetName.ApplicantRespondedInfo];
