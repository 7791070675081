import { ReactNode, RefObject, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { Button as MagritteButton, useBreakpoint as useMagritteBreakpoint } from '@hh.ru/magritte-ui';
import Button, { ButtonKind, ButtonAppearance, ButtonScale } from 'bloko/blocks/button';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SendFloodlightConversion from 'HH/SendFloodlightConversion';
import scrollToElement from 'Utils/ScrollToElement';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { vacancySetVisibilityContactsAction } from 'src/models/vacancyView';

import VacancyContactsDrop from 'src/components/VacancyView/VacancyContactsDrop';
import useContactsAnalytics from 'src/components/VacancyView/hooks/useContactsAnalytics';

const TrlKeys = {
    showContacts: 'vacancy.action.showContacts',
    showContactsCompact: 'vacancy.action.showContacts.compact',
};

const SCROLL_TOP_OFFSET = -25;

interface VacancyActionShowContactsProps {
    contactsNodeRef?: RefObject<HTMLDivElement>;
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    constructorBrandedVacancy?: boolean;
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
}

const VacancyActionShowContacts: TranslatedComponent<VacancyActionShowContactsProps> = ({
    contactsNodeRef,
    vacancyBodyFooterNodeRef,
    isRedesign,
    isMagritteVacancy,
    constructorBrandedVacancy,
    trls,
}) => {
    const contactInfo = useSelector((state) => state.vacancyView.contactInfo);
    const isVisible = useSelector((state) => state.vacancyView.isVisibleContacts);
    const isContactsRedesignExpBEnabled = useExperiment('web_redesign_vacancy_contacts_b');
    const isContactsRedesignExpCEnabled = useExperiment('web_redesign_vacancy_contacts_c');
    const isContactsRedesignExpEnabled = isContactsRedesignExpBEnabled || isContactsRedesignExpCEnabled;
    const [isLocalStateContactsVisible, setLocalStateContactsVisible] = useState(false);
    const isDropVisible = isContactsRedesignExpEnabled ? isVisible && isLocalStateContactsVisible : isVisible;
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const employerId = useSelector((state) => state.vacancyView.company.id);
    const userType = useSelector((state) => state.userType);
    const dispatch = useDispatch();
    const sendViewContactsAnalytics = useContactsAnalytics({
        place: 'vacancy_top_button',
    });
    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;
    const isAnonymousOnXs = userType === UserType.Anonymous && isXs;
    const isBrandingPreview = useSelector((state) => state.vacancyView.isBrandingPreview);
    const { isMobile } = useMagritteBreakpoint();
    const activatorRef = useRef(null);

    const showContactsHandler = useCallback(() => {
        if (!isVisible) {
            dispatch(vacancySetVisibilityContactsAction(true));
            setLocalStateContactsVisible(true);
            sendViewContactsAnalytics({
                vacancyId,
                employerId,
                userType,
            });
            SendFloodlightConversion({
                type: 'hh_co0',
                cat: 'hh_bu0',
            });
            const contactsElement = contactsNodeRef?.current;
            if (contactsElement && !isRedesign) {
                scrollToElement(contactsElement, {
                    topOffset: SCROLL_TOP_OFFSET,
                    centered: false,
                });
            }
        }
    }, [dispatch, sendViewContactsAnalytics, vacancyId, employerId, userType, contactsNodeRef, isRedesign, isVisible]);
    const getKind = () => {
        if (constructorBrandedVacancy) {
            return ButtonKind.Inversed;
        }
        if (isRedesign) {
            return ButtonKind.Success;
        }
        return undefined;
    };

    const renderContactsButton = () => {
        const isCompactText = isXs || isMagritteVacancy;
        const textContent = trls[isCompactText ? TrlKeys.showContactsCompact : TrlKeys.showContacts];
        const commonProps = {
            'data-qa': 'show-employer-contacts show-employer-contacts_top-button',
            onClick: isBrandingPreview ? undefined : showContactsHandler,
            disabled: isBrandingPreview,
            stretched: true,
        } as const;

        return isMagritteVacancy ? (
            <MagritteButton
                style="accent"
                mode="secondary"
                size={isMobile ? 'small' : 'medium'}
                ref={activatorRef}
                {...commonProps}
            >
                {textContent}
            </MagritteButton>
        ) : (
            <Button
                appearance={ButtonAppearance.Outlined}
                kind={getKind()}
                scale={isRedesign ? ButtonScale.Large : undefined}
                innerRef={activatorRef}
                {...commonProps}
            >
                {textContent}
            </Button>
        );
    };

    const renderWrapper = (content: ReactNode) => (
        <div
            className={classnames('vacancy-action vacancy-action_stretched', {
                'vacancy-action_stretched-redesigned': isRedesign,
            })}
        >
            {content}
        </div>
    );

    if (isBrandingPreview) {
        return renderWrapper(renderContactsButton());
    }

    if (!contactInfo || isAnonymousOnXs) {
        return null;
    }

    return renderWrapper(
        isRedesign ? (
            <VacancyContactsDrop
                show={isDropVisible}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                place="top"
                activatorRef={activatorRef}
                onClose={() => {
                    setLocalStateContactsVisible(false);
                    dispatch(vacancySetVisibilityContactsAction(false));
                }}
            >
                {renderContactsButton()}
            </VacancyContactsDrop>
        ) : (
            renderContactsButton()
        )
    );
};

export default translation(VacancyActionShowContacts);
