import { FC, RefObject, MutableRefObject, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Card, VSpacing, VSpacingContainer, useBreakpoint } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';

import VacancyActions from 'src/components/VacancyView/VacancyActions';
import VacancyCompensationFrequency from 'src/components/VacancyView/VacancyCompensationFrequency';
import VacancyEmployment from 'src/components/VacancyView/VacancyEmployment';
import VacancyOnlineUsersCounter from 'src/components/VacancyView/VacancyOnlineUsersCounter';
import VacancyResponseQuestions from 'src/components/VacancyView/VacancyResponseQuestions';
import VacancyTitleRedesigned from 'src/components/VacancyView/VacancyTitleRedesigned';

import styles from './vacancy-block.less';

interface VacancyBlockTopWrapperProps extends PropsWithChildren {
    hasWrapper: boolean;
    hasWrapperCardOnly: boolean;
    isMobile: boolean;
}

const VacancyBlockTopWrapper: FC<VacancyBlockTopWrapperProps> = ({
    children,
    hasWrapper,
    hasWrapperCardOnly,
    isMobile,
}) => {
    if (!hasWrapper) {
        return <>{children}</>;
    }

    const cardContent = (
        <Card stretched padding={isMobile ? 16 : 24} borderRadius={24} borderWidth="default">
            {children}
        </Card>
    );

    if (hasWrapperCardOnly) {
        return cardContent;
    }

    return (
        <ColumnsRow>
            <Column xs="4" s="8" m="12" l="10">
                {cardContent}
            </Column>
            <VSpacing default={24} gteL={48} />
        </ColumnsRow>
    );
};

interface VacancyBlockTopProps {
    resumeFormNodeRef: RefObject<HTMLDivElement>;
    contactsNodeRef: RefObject<HTMLDivElement>;
    vacancyBodyFooterNodeRef: MutableRefObject<HTMLDivElement>;
    hasWrapper?: boolean;
    hasWrapperCardOnly?: boolean;
    isChatQuestionExp?: boolean;
    hasQuestion?: boolean;
}

const VacancyBlockTop: FC<VacancyBlockTopProps> = ({
    resumeFormNodeRef,
    contactsNodeRef,
    vacancyBodyFooterNodeRef,
    hasWrapper = true,
    hasWrapperCardOnly = false,
    isChatQuestionExp,
    hasQuestion,
}) => {
    const { isMobile } = useBreakpoint();

    return (
        <VacancyBlockTopWrapper hasWrapper={hasWrapper} hasWrapperCardOnly={hasWrapperCardOnly} isMobile={isMobile}>
            <VSpacingContainer default={isMobile ? 16 : 24}>
                <Card stretched padding={isMobile ? 8 : 0}>
                    <VSpacingContainer default={12}>
                        <VacancyTitleRedesigned isMobile={isMobile} />
                        <VSpacingContainer default={8}>
                            <VacancyCompensationFrequency isRedesign isMagritteVacancy isMobile={isMobile} />
                            <VacancyEmployment isRedesign isMagritteVacancy isMobile={isMobile} />
                            <VacancyOnlineUsersCounter isRedesign isMagritteVacancy isMobile={isMobile} />
                        </VSpacingContainer>
                    </VSpacingContainer>
                </Card>
                <div className="noprint">
                    <VacancyActions
                        contactsNodeRef={contactsNodeRef}
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        resumeFormNodeRef={resumeFormNodeRef}
                        isRedesign
                        isMagritteVacancy
                        hasQuestion={hasQuestion}
                    />
                </div>
            </VSpacingContainer>
            {isChatQuestionExp && !hasQuestion && (
                <div className={classnames(styles.question, 'noprint')}>
                    <VacancyResponseQuestions
                        type="question"
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        hasBottomGap={false}
                        hasSuggest={false}
                    />
                </div>
            )}
        </VacancyBlockTopWrapper>
    );
};

export default VacancyBlockTop;
