import { FC, PropsWithChildren, ReactNode, Ref, useRef } from 'react';
import classnames from 'classnames';

import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    NavigationBar,
    Text,
    Tooltip,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { QuestionCircleOutlinedSize16, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import useOnOffState from 'src/hooks/useOnOffState';

import { ActionPropsType } from 'src/components/FieldTip/types';

import styles from './field-tip.less';

interface FieldTipProps extends PropsWithChildren {
    tipContent: ReactNode;
    fieldName: string;
    withoutVertPadding?: boolean;
    stretched?: boolean;
    action?: ActionPropsType;
}

const FieldTip: FC<FieldTipProps> = ({
    tipContent,
    fieldName,
    children,
    withoutVertPadding = false,
    stretched = false,
    action,
}) => {
    const [isTooltipVisible, showTooltip, hideTooltip] = useOnOffState(false);
    const [isBottomSheetVisible, showBottomSheet, hideBottomSheet] = useOnOffState(false);
    const { isMobile } = useBreakpoint();

    const activatorRef = useRef<HTMLElement>(null);

    const { text: actionText, ...actionProps } = action || ({} as ActionPropsType);

    const buttons = action
        ? () => (
              <>
                  {!!action && (
                      <Button {...actionProps} style="contrast" mode="primary" size="small">
                          {actionText}
                      </Button>
                  )}
              </>
          )
        : undefined;

    return (
        <div className={styles.fieldTipWrapper}>
            <div className={classnames(styles.fieldTip, { [styles.fieldTipStretched]: stretched })}>
                {children}

                <div
                    className={classnames({ [styles.fieldTipActionWithoutVertPadding]: withoutVertPadding })}
                    ref={activatorRef as Ref<HTMLDivElement>}
                >
                    <Action
                        icon={QuestionCircleOutlinedSize16}
                        onClick={() => {
                            if (isMobile) {
                                showBottomSheet();
                            }
                        }}
                        onFocus={showTooltip}
                        onBlur={hideTooltip}
                        onMouseEnter={showTooltip}
                        onMouseLeave={hideTooltip}
                        mode="secondary"
                        disablePadding={withoutVertPadding}
                    />
                </div>

                {!isMobile && (
                    <Tooltip
                        visible={isTooltipVisible}
                        placement="right-top"
                        activatorRef={activatorRef}
                        buttons={buttons}
                    >
                        {tipContent}
                    </Tooltip>
                )}

                {isMobile && (
                    <BottomSheet
                        visible={isBottomSheetVisible}
                        onClose={hideBottomSheet}
                        header={
                            <NavigationBar
                                title={fieldName}
                                showDivider="always"
                                right={<Action icon={CrossOutlinedSize24} onClick={hideBottomSheet} />}
                            />
                        }
                        footer={
                            action && (
                                <BottomSheetFooter>
                                    <Button
                                        {...actionProps}
                                        onClick={(event) => {
                                            actionProps?.onClick?.(event);
                                            hideBottomSheet();
                                        }}
                                        mode="secondary"
                                    >
                                        {actionText}
                                    </Button>
                                </BottomSheetFooter>
                            )
                        }
                    >
                        <Text>{tipContent}</Text>
                    </BottomSheet>
                )}
            </div>
        </div>
    );
};

export default FieldTip;
