import { Component, createRef } from 'react';

let loadBannerOnVisible = () => {};

if (!process.env.SSR) {
    loadBannerOnVisible = require('HH/LoadBannerOnVisibleModule').default;
}

class BannersGroup extends Component {
    static defaultProps = {
        templateName: 'HHC-Banners-Place-Template',
        groupClassNames: '',
    };

    wrapperRef = createRef();
    abortController = null;

    componentDidMount() {
        this.initBanners();
    }

    componentWillUnmount() {
        this.abortController?.abort();
    }

    initBanners() {
        this.abortController = new AbortController();
        const { banners, templateName } = this.props;
        const bannerParams = {
            ids: banners.map(({ id }) => id),
            templateName,
        };
        loadBannerOnVisible(this.wrapperRef.current, bannerParams, this.abortController.signal);
    }

    render() {
        const { groupClassNames, children } = this.props;
        return (
            <div className={groupClassNames} ref={this.wrapperRef}>
                {children}
            </div>
        );
    }
}

export default BannersGroup;
