import { FC, useEffect, useRef, RefObject, ReactNode } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Backplate, MainContentWrapper } from '@hh.ru/branding-packages';
import { Card } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import { useSelector } from 'src/hooks/useSelector';

import Form from 'src/components/VacancyView/SignUpBlock/Form';

interface Props {
    isBrandingConstructor?: boolean;
    blockRef: RefObject<HTMLDivElement>;
}

const SignUpBlock: FC<Props> = ({ isBrandingConstructor, blockRef }) => {
    const showSignupForm = useSelector((state) => state.vacancyView.showSignupForm);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const analyticsSent = useRef(false);

    useEffect(() => {
        if (!showSignupForm) {
            return undefined;
        }
        const signupFormTop = blockRef?.current?.offsetTop || 0;
        const scrollHandler = () => {
            if (!analyticsSent.current && window.scrollY + window.innerHeight / 2 > signupFormTop) {
                Analytics.sendHHEvent('applicantRegistrationAttempt', {
                    vacancy_id: vacancyId,
                });
                analyticsSent.current = true;
            }
        };
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [blockRef, showSignupForm, vacancyId]);

    if (!showSignupForm) {
        return null;
    }

    const renderLayout = (content: ReactNode) => {
        if (isBrandingConstructor) {
            return (
                <MainContentWrapper>
                    <Backplate separate forceStylesBackplate>
                        {content}
                    </Backplate>
                </MainContentWrapper>
            );
        }
        return (
            <ColumnsRow>
                <Column xs="4" s="8" m="12" l="10">
                    {content}
                    <VSpacing base={9} xs={6} />
                </Column>
            </ColumnsRow>
        );
    };

    return (
        <div className="noprint">
            {renderLayout(
                <Card stretched borderWidth="default" padding={24} borderRadius={24} ref={blockRef}>
                    <MagritteWrapper isEnabled>
                        <Form />
                    </MagritteWrapper>
                </Card>
            )}
        </div>
    );
};

export default SignUpBlock;
