import { Fragment } from 'react';

import BannersGroup from 'src/components/Banner/BannersGroup';

import ClickmeReferralLink from 'src/components/Clickme/ReferralLink';

const Clickme = ({ banners, showReferralLink = false, children, groupClassNames = '' }) => (
    <Fragment>
        {showReferralLink && <ClickmeReferralLink />}
        <BannersGroup banners={banners} groupClassNames={groupClassNames}>
            {children}
        </BannersGroup>
    </Fragment>
);

export default Clickme;
