import { useRef, FC } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import Clickme from 'src/components/Clickme';
// tempexp_clickme_banner_755_magritt_next-line
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';

import { useTrackBannersClickEvent } from 'src/components/VacancyView/useBannersAnalytics';

const VacancyAdaptiveBanners: FC = () => {
    // tempexp_clickme_banner_755_magritt_next-line
    const isMagritteBannersExp = useExperiment('clickme_banner_755_magritt', true, false);
    const banners = useSelector(({ banners }) => banners);
    const adsVacancyParams = useSelector((state) => state.adsVacancyParams);

    const rightColumnBanners = banners['right-column'];
    const rightColumnClickmeAdaptiveBanners = banners['right-column-clickme-adaptive'];

    const containerRef = useRef(null);
    useTrackBannersClickEvent({ containerRef });
    if (!rightColumnBanners?.length && !rightColumnClickmeAdaptiveBanners?.length) {
        return null;
    }
    return (
        <div className="noprint" ref={containerRef}>
            <VSpacing base={6} />
            <div className="vacancy-adaptive-banners">
                {rightColumnBanners && rightColumnBanners.length > 0 && (
                    // tempexp_clickme_banner_755_magritt_next-line
                    <Column xs="4" s={isMagritteBannersExp ? '8' : '4'} m="8" l="0">
                        {rightColumnBanners.map((banner) => (
                            <AdsExternalBanner
                                {...banner}
                                cId={`adaptive_${banner.cId}`}
                                key={banner.id}
                                bannerAdTargeting={adsVacancyParams}
                            />
                        ))}
                    </Column>
                )}
                {/* tempexp_clickme_banner_755_magritt_next-line */}
                <VSpacing base={8} />
                {rightColumnClickmeAdaptiveBanners && rightColumnClickmeAdaptiveBanners.length > 0 && (
                    // tempexp_clickme_banner_755_magritt_start
                    <Column xs="4" s={isMagritteBannersExp ? '8' : '4'} m="8" l="0">
                        <Clickme
                            groupClassNames={`banner-group-651-652__${isMagritteBannersExp ? 'magritte' : 'bloko'}`}
                            banners={rightColumnClickmeAdaptiveBanners}
                        />
                        {/* tempexp_clickme_banner_755_magritt_end */}
                    </Column>
                )}
            </div>
        </div>
    );
};

export default VacancyAdaptiveBanners;
