import { FC, RefObject } from 'react';

import Column, { ColumnsRow } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import VacancyCompanyRedesigned from 'src/components/VacancyView/VacancyCompanyRedesigned';
import VacancyVideo from 'src/components/VacancyView/VacancyVideo';

interface Props {
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
}

const VacancyBlockEmployer: FC<Props> = ({ vacancyBodyFooterNodeRef }) => {
    return (
        <ColumnsRow>
            <Column xs="4" s="8" m="12" l="6">
                <VacancyCompanyRedesigned />
                <VacancyVideo vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
            </Column>
            <VSpacing base={14} xs={9} />
        </ColumnsRow>
    );
};

export default VacancyBlockEmployer;
